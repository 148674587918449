import React from "react";

import { Player, Shortcut, VolumeMenuButton, ControlBar, FullscreenToggle } from "video-react";
import './TvControlbar.css'
import HLSSource from "./HLSSource";
import LiveLoading from "../images/sprite/cover-live.png";

export default function VideoPlayer(props) {
  // console.log(tvUrl,'props.liveStream');
  return (
    <Player autoPlay muted playsInline poster={LiveLoading} className="Playyyerrr">
      <HLSSource isVideoChild src={props.liveStream} />
      <Shortcut clickable={false} />
      <ControlBar muted disableDefaultControls autoHide={true}>
        <VolumeMenuButton />
        <FullscreenToggle />
      </ControlBar>
    </Player>
  );
}